import * as api from './api';
import actionTypes, { l } from './action-types';
var text = {
  singular: 'location',
  plural: 'locations'
};

function getListRequest() {
  return {
    type: actionTypes["GET_".concat(l, "_LIST_REQUEST")]
  };
}

function getListSuccess(entities, numTotal) {
  return {
    type: actionTypes["GET_".concat(l, "_LIST_SUCCESS")],
    entities: entities,
    numTotal: numTotal
  };
}

function getListFailure(errors) {
  return {
    type: actionTypes["GET_".concat(l, "_LIST_FAILURE")],
    errors: errors
  };
}

export function getLocationList(tokenCache) {
  var page = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  var perPage = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : -1;
  return function (dispatch, getState) {
    var state = getState();
    dispatch(getListRequest());
    api.list(tokenCache, state.config.location.apiUrl, {
      sort: 'name',
      order: 'asc',
      page: page,
      perPage: perPage
    }).then(function (data) {
      if (data.errors) dispatch(getListFailure(data.errors));else dispatch(getListSuccess(data.entities, data.numTotal));
    })["catch"](function (_) {
      return dispatch(getListFailure(["Something went wrong and ".concat(text.plural, " could not be fetched. Please try again.")]));
    });
  };
}

function getOneRequest() {
  return {
    type: actionTypes["GET_ONE_".concat(l, "_REQUEST")]
  };
}

function getOneSuccess(entity) {
  return {
    type: actionTypes["GET_ONE_".concat(l, "_SUCCESS")],
    entity: entity
  };
}

function getOneFailure(errors) {
  return {
    type: actionTypes["GET_ONE_".concat(l, "_FAILURE")],
    errors: errors
  };
}

export function getOneLocation(tokenCache, slugOrId) {
  return function (dispatch, getState) {
    var state = getState();
    dispatch(getOneRequest());
    return api.get(tokenCache, state.config.location.apiUrl, {
      slugOrId: slugOrId
    }).then(function (result) {
      if (result.success) {
        dispatch(getOneSuccess(result.entity));
      } else {
        dispatch(getOneFailure(result.errors));
      }

      return result;
    })["catch"](function (_) {
      var errors = ["Something went wrong and the ".concat(text.singular, " could not be fetched. Please try again.")];
      dispatch(getOneFailure(errors));
      return {
        status: 500,
        errors: errors
      };
    });
  };
}