import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTokenCache } from '@@client/lib/hooks';
import { getLocationList } from './actions';
export default function loadLocationList() {
  var page = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
  var perPage = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : -1;
  var locationStore = useSelector(function (state) {
    return state.location;
  });
  var dispatch = useDispatch();
  var tokenCache = useTokenCache();
  useEffect(function () {
    // already doing, no need to fetch again
    if (locationStore.list.doing) return;
    dispatch(getLocationList(tokenCache, page, perPage));
  }, [page, perPage]);
  return null;
}