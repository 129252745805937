import urlJoin from 'url-join';
import { getOffset } from '@@client/lib/pagination';
import { paramUrl } from '@@client/lib/url';
import { bearer, responseData, cacheStandardRequest } from '@@client/lib/request';
import { isUuid } from '@@client/lib/util';
export var list = cacheStandardRequest(600, function (tokenCache, apiUrl, _ref) {
  var page = _ref.page,
      perPage = _ref.perPage,
      order = _ref.order;
  // 600 seconds == 10 minutes
  var query = {
    order: order,
    num: perPage,
    offset: getOffset(page, perPage)
  };
  var url = paramUrl(urlJoin(apiUrl, '/api/v1/store'), query);
  return tokenCache.get().then(function (token) {
    return fetch(url, {
      method: 'get',
      headers: {
        'Authorization': bearer(token)
      }
    });
  }).then(responseData).then(function (_ref2) {
    var response = _ref2.response,
        data = _ref2.data;

    if (response.ok) {
      return {
        success: true,
        entities: data.entities,
        numTotal: data.numTotal
      };
    } else {
      return {
        success: false,
        errors: data.errors
      };
    }
  });
});
export var get = cacheStandardRequest(600, function (tokenCache, apiUrl, _ref3) {
  var slugOrId = _ref3.slugOrId;
  // 600 seconds == 10 minutes
  var url = isUuid(slugOrId) ? urlJoin(apiUrl, '/api/v1/store', slugOrId) : urlJoin(apiUrl, '/api/v1/store/slug', slugOrId);
  return tokenCache.get().then(function (token) {
    return fetch(url, {
      method: 'get',
      headers: {
        'Authorization': bearer(token)
      }
    });
  }).then(responseData).then(function (_ref4) {
    var response = _ref4.response,
        data = _ref4.data;

    if (response.ok) {
      return {
        status: response.status,
        success: true,
        entity: data
      };
    } else {
      return {
        status: response.status,
        success: false,
        errors: data.errors
      };
    }
  });
});