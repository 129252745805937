export default function Youtube({url = null, videoId = null}) {
    if(videoId) url = `https://www.youtube.com/embed/${videoId}`

    return (
        <>
            <iframe className='video' src={url} frameBorder='0' allowFullScreen />
            <style jsx>
                {`
                    .video {
                        width: 100%;
                        height: 100%;
                    }
                `}
            </style>
        </>
    )
}
