import {useState} from 'react'
import {useSelector} from 'react-redux'
import MarkdownJsx from 'markdown-to-jsx'
import Link from 'next/link'
import {useDefaultWarrantyInfo, useNonDefaultWarrantyInfos} from '@@client/cart/cart-hooks'
import {useConfig, useChat} from '@@client/lib/hooks'
import NewsletterModal from '@@client/newsletter/NewsletterModal'
import Youtube from '@@client/components/Youtube'
import loadLocationList from '@@client/location/loadLocationList'
import WarrantyOptionCard from '@@client/cart/WarrantyOptionCard'

const NewsletterButton = ({children}) => {
    const [newsletterModalIsOpen, setNewsletterModalOpen] = useState(false)

    return (
        <>
            <button className='btn btn--yellow' onClick={() => setNewsletterModalOpen(true)}>{children}</button>

            <NewsletterModal
                isOpen={newsletterModalIsOpen}
                onClose={() => setNewsletterModalOpen(false)}
            />
        </>
    )
}

const StandardWarrantyPeriod = ({lowerCase = false}) => {
    const defaultWarrantyInfo = useDefaultWarrantyInfo()

    if(!defaultWarrantyInfo) return null

    const value = lowerCase
        ? defaultWarrantyInfo.periodHyphenated.toLowerCase()
        : defaultWarrantyInfo.periodHyphenated

    return <span>{value}</span>
}

const LinkButton = ({href, external, children}) => {
    return (
        <Link href={href}>
            <a target={external ? '_blank' : undefined} rel={external ? 'noopener noreferrer' : undefined}>
                <button className='btn btn--yellow'>{children}</button>
            </a>
        </Link>
    )
}

const overrides = {
    Newsletter: ({children}) => {
        return <NewsletterButton>{children}</NewsletterButton>
    },
    CallUs: ({children}) => {
        const config = useConfig()

        const linkContent = children || config.contact.phoneHuman

        return (<a href={`tel:${config.contact.phoneFull}`}>{linkContent}</a>)
    },
    NumLocations: ({singular, plural, loading}) => {
        singular = singular || plural
        plural = plural || singular

        loadLocationList()
        const locationStore = useSelector(state => state.location)
        const locations = locationStore.list.entities

        const content = !locations.length
            ? loading
            : locations.length === 1
                ? `${locations.length} ${singular}`
                : `${locations.length} ${plural}`

        return <span>{content}</span>
    },
    Youtube: (props) => {
        return (<>
            <div className='video'><Youtube {...props} /></div>
            <style jsx>
                {`
                    .video {
                        aspect-ratio: 16 / 9;
                    }
                `}
            </style>
        </>)
    },
    HasChat: ({children}) => {
        const chat = useChat()

        if(!chat.isAvailable) return null

        return children
    },
    NoChat: ({children}) => {
        const chat = useChat()

        if(chat.isAvailable) return null

        return children
    },
    LiveChat: ({variant, children}) => {
        variant = variant || 'link'

        switch(variant) {
            case 'link':
                return <button className='m--as-link'>{children}</button>
            case 'button':
                return <button>{children}</button>
        }
    },
    StandardWarrantyBanner: ({children}) => {
        const defaultWarrantyInfo = useDefaultWarrantyInfo()

        if(!defaultWarrantyInfo) return null

        return (
            <>
                <div className='default-option'>
                    <div className='leader'>{children}</div>
                    <div className='title'>{defaultWarrantyInfo.titleLong}</div>
                </div>
                <style jsx>
                    {`
                        @import '@css/variables.css';

                        .default-option {
                            padding: 15px 25px;
                            text-align: center;
                            border: 1px solid $c--blue--darker;
                            color: $c--blue--darker;
                            font-weight: $weight--bold;
                        }

                        .title {
                            font-size: $size--larger;
                        }
                    `}
                </style>
            </>
        )
    },
    WarrantyOptions: () => {
        const nonDefaultWarrantyInfos = useNonDefaultWarrantyInfos()

        if(!nonDefaultWarrantyInfos) return null

        const entries = Object.entries(nonDefaultWarrantyInfos)

        if(!entries.length) return null

        return (
            <div>
                {entries.map(([key, info]) => <WarrantyOptionCard key={key} warrantyInfo={info} />)}
            </div>
        )
    },
    Row: ({children, cols = 1, startAt = 'lg'}) => {
        return (
            <>
                <div className={`md-row start-at-${startAt}`}>{children}</div>
                <style jsx>
                    {`
                        @import '@css/variables.css';

                        .md-row {
                            display: flex;
                            flex-direction: column;
                            grid-template-columns: repeat(${cols}, 1fr);
                            gap: 30px;
                        }

                        .start-at-xl {
                            @media(--xl) {
                                display: grid;
                            }
                        }

                        .start-at-lg {
                            @media(--lg) {
                                display: grid;
                            }
                        }

                        .start-at-md {
                            @media(--md) {
                                display: grid;
                            }
                        }

                        .start-at-sm {
                            @media(--sm) {
                                display: grid;
                            }
                        }

                        .start-at-xs {
                            @media(--xs) {
                                display: grid;
                            }
                        }
                    `}
                </style>
            </>
        )
    },
    Column: ({children}) => {
        return <div>{children}</div>
    },
    Space: ({size}) => {
        return <div style={{height: `${size}px`}} />
    },
    Separator: ({size = 2}) => {
        return <>
            <div className="separator" style={{height: `${size}px`}} />
            <style jsx>
                {`
                    @import '@css/variables.css';

                    .separator {
                        margin: 20px 0;
                        background: $c--blue--darker;
                    }
                `}
            </style>
        </>
    },
    Link: ({children, ...props}) => {
        return <Link {...props}><a>{children}</a></Link>
    },
    StandardWarrantyPeriod: (props) => {
        return <StandardWarrantyPeriod {...props} />
    },
    LinkButton: (props) => {
        return <LinkButton {...props} />
    },
}

export default function Markdown({children}) {
    return <MarkdownJsx options={{overrides}}>{children}</MarkdownJsx>
}
